import * as React from "react"
import { navigate } from "gatsby"

import { CardGameLayout } from "@monorepo/shared"
import { hitler } from "@monorepo/games/hitler"

const Game = () => (
  <CardGameLayout
    game={hitler}
    language="fi"
    onGameEnd={() => navigate("/end")}
  />
)

export default Game
